<template>
  <div class="drivers-step1-content">
    <div class="bg-white">
      <div class="pl-24">
        <label class="ml-10 require text-18 text-main font-500">身份证正反面</label>
      </div>
      <div class="px-24 py-8 id-card-wrap d-flex justify-space-between">
        <tos-uploader
          class="id-card-front id-card"
          v-model="cardFrontFileList"
          :max-count="1"
          @afterUploaded="afterUploadedIdCardFront"
          bgType="shenfenzheng-zhu"
        >
          身份证人像面
        </tos-uploader>
        <tos-uploader
          class="id-card-back id-card"
          @afterUploaded="afterUploadedIdCardBack"
          v-model="cardBackFileList"
          :max-count="1"
          bgType="shenfenzheng-fu"
          >身份证国徽面
        </tos-uploader>
      </div>
    </div>

    <van-form class="px-12">
      <template v-for="(item, key) in FormObj">
        <van-field
          :key="key"
          :required="item.required"
          v-model="verifiedInfo[key]"
          v-if="!item.custom && !item.hidden"
          input-align="right"
          :name="key"
          :label="item.label"
          :placeholder="'请输入'"
          :type="item.type"
          :autosize="item.autosize"
          :rules="item.rules"
          rows="1"
          :disabled="item.disabled"
        />
      </template>

      <div
        class="flex items-center justify-between date-picker"
        style="height:0.64rem;padding: 0 0.16rem 0 0.16rem;"
        @click="showDatePicker"
        v-if="!FormObj['idCardStartDate'].hidden"
      >
        <div class="relative flex-shrink-0 label idcard"><span class="required">*</span> 身份证有效期</div>
        <div>
          {{ formatTime(verifiedInfo.idCardStartDate) }} <span class="px-4">-</span>
          {{ formatTime(verifiedInfo.idCardEndDate) }}
        </div>
      </div>
    </van-form>

    <DatetimePicker
      v-model="visible"
      :startDate="verifiedInfo.idCardStartDate"
      :endDate="verifiedInfo.idCardEndDate"
      @change="onDatetimePickerChange"
    />

    <!-- 按钮栏 -->
    <div>
      <div class="truck-btn-box">
        <Button :disabled="disabled" size="large" @click="handleToNext" type="primary">下一步</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon/index.vue';
import TosUploader from '@/components/uploader/uploader.vue';
import { defineComponent, ref, onMounted, reactive, computed } from '@vue/composition-api';
import { Button, Popup, Toast } from 'vant';
import { useDriverVerifiedStore } from '@/store/driverVerfied';
import { useUserStore } from '@/store/user';
import { driverStepFormOne } from '../constants';
import DatetimePicker from '@/components/DatetimePicker';

import { buildFormByAuthModeFields } from '@/utils/authMode';
import dayjs from 'dayjs';

import { isValidEndDate } from '../date-validate';
import { apiOcrIdCard } from '@/api/ocr';
import { parseOcrDate } from '@/utils/validateTimeRanges';
export default defineComponent({
  name: 'customer',
  components: {
    Icon,
    Button,
    TosUploader,
    Popup,
    DatetimePicker
  },
  setup() {
    const userVerifiedStore = useDriverVerifiedStore();
    const userStore = useUserStore();
    const isReviewOpen = userStore.showReviewKeys;
    // 如果是认证过然后修改认证 【姓名、手机号、身份证号】不可编辑
    const formOne = driverStepFormOne(isReviewOpen);

    buildFormByAuthModeFields(formOne, userVerifiedStore.authModeFields);
    const FormObj = reactive(formOne);

    const userInfoStore = useUserStore();
    if (!userInfoStore.showReviewKeys) {
      FormObj.idCardIssueOrganization.hidden = true;
    }
    // 改变页面背景颜色
    onMounted(() => {
      // 处理修改身份证图片显示问题
      const idCardFrontUrl =
        userVerifiedStore.verifiedInfo?.idCardFrontUrl || userInfoStore.userInfo?.realNameAuthVO?.idCardFrontUrl;
      const idCardBackUrl =
        userVerifiedStore.verifiedInfo?.idCardBackUrl || userInfoStore.userInfo?.realNameAuthVO?.idCardBackUrl;

      document.querySelector('body').setAttribute('style', 'background-color:#f5f5f5');
      // 如果缓存中有图片需要进行回填
      if (idCardFrontUrl) {
        cardFrontFileList.value = [
          {
            fileName: idCardFrontUrl,
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
      if (idCardBackUrl) {
        cardBackFileList.value = [
          {
            fileName: idCardBackUrl,
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
    });

    const cardFrontFileList = ref([]);
    const cardBackFileList = ref([]);

    // debugger;
    // 初始化表单数据
    // const userVertifiedRef = computed(() => {
    //   return {
    //     ...userInfoStore.userInfo.realNameAuthVO,
    //     driverPhone: userInfoStore.userInfo.driverPhone
    //     // ...userVerifiedStore.verifiedInfo
    //   };
    // });

    const verifiedInfo = computed(() => userVerifiedStore.verifiedInfo);
    const driverPhone = userVerifiedStore.verifiedInfo.driverPhone || userInfoStore.userInfo.driverPhone;
    userVerifiedStore.updateInfo({ driverPhone: driverPhone });
    const userVertifiedRef = computed(() => userVerifiedStore.verifiedInfo);

    // 是否可以进行下一步
    const next = ref(false);

    //
    const visible = ref(false);
    const onDatetimePickerChange = ({ start, end }) => {
      userVerifiedStore.updateInfo({
        idCardStartDate: start,
        idCardEndDate: end
      });

      // console.log(userVerifiedStore.verifiedInfo);
    };

    const showDatePicker = () => {
      visible.value = !visible.value;
    };

    const formatTime = timestamp => {
      if (!timestamp) return null;
      const date = dayjs(+timestamp);
      if (date.year() >= 2199) {
        return '长期';
      }
      return date.format('YYYY-MM-DD');
    };

    // idCardBackUrl: '',
    // idCardFrontUrl: '',
    // 身份证上传需要进行ocr，【手机号，姓名，身份证号】不能变
    const disabled = ref(false);
    const afterUploadedIdCardFront = async file => {
      file.status = 'uploading';
      file.message = '识别中';
      disabled.value = true;
      try {
        const res = await apiOcrIdCard({ frontImageUrl: file.url });
        const { data } = res;
        const { address, gender, nation, birthday } = data;
        userVerifiedStore.updateInfo({
          address,
          gender: gender === '男' ? 1 : 2,
          nation,
          birthday
        });
      } finally {
        disabled.value = false;
        file.status = 'done';
      }
    };

    const afterUploadedIdCardBack = async file => {
      file.status = 'uploading';
      file.message = '识别中';

      disabled.value = true;
      try {
        const res = await apiOcrIdCard({ backImageUrl: file.url });
        const { data } = res;
        const { authority, effectiveDate, expirationDate } = data;
        const changedObj = {};
        const sDate = parseOcrDate(effectiveDate);
        const eDate = parseOcrDate(expirationDate);
        if (sDate && eDate) {
          changedObj.idCardStartDate = sDate;
          changedObj.idCardEndDate = eDate;
        }
        if (authority) {
          changedObj.idCardIssueOrganization = authority;
        }
        userVerifiedStore.updateInfo(changedObj);
      } catch (error) {
        console.error(error);
      } finally {
        disabled.value = false;
        file.status = 'done';
      }
    };

    return {
      next,
      FormObj,
      cardFrontFileList,
      cardBackFileList,
      userVertifiedRef,
      handleToNext: () => {
        // TODO: 调用一个验证接口 验证 该司机是否已经审核
        // debugger;

        // 去下一步对数据进行缓存 需要同步到storage中
        if (!cardFrontFileList?.value[0]?.fileName || !cardBackFileList?.value[0]?.fileName) {
          return Toast.fail('图片信息不能为空!');
        }

        // 做检验 一个个堆
        if (userVerifiedStore.authModeFields?.address) {
          if (!userVertifiedRef.value.address) {
            Toast.fail('身份证地址不能为空');
            return;
          }
          if (userVertifiedRef.value.address.length < 5) {
            Toast.fail('身份证地址不少于5位字符');
            return;
          }
        }

        // 校验下身份证地址
        if (userVerifiedStore.authModeFields?.idCardStartDate) {
          const { idCardStartDate, idCardEndDate } = userVerifiedStore.verifiedInfo;
          if (!isValidEndDate(idCardStartDate, idCardEndDate) || !idCardEndDate || !idCardStartDate) {
            Toast.fail('身份证有效期不符合规则');
            return;
          }
        }
        if (!FormObj.idCardIssueOrganization.hidden) {
          if (FormObj.idCardIssueOrganization.required && !userVerifiedStore.verifiedInfo.idCardIssueOrganization) {
            Toast.fail(`签发机关不能为空`);
            return;
          }
          const rule = FormObj.idCardIssueOrganization.rules[0];
          const isValid = rule ? rule?.validator(userVerifiedStore.verifiedInfo.idCardIssueOrganization) : true;
          if (!isValid) {
            Toast.fail(`签发机关${rule.message}`);
            return;
          }
        }

        userVerifiedStore.updateInfo({
          ...userVertifiedRef.value,
          idCardStartDate: userVerifiedStore.verifiedInfo.idCardStartDate,
          idCardEndDate: userVerifiedStore.verifiedInfo.idCardEndDate,
          idCardFrontUrl: cardFrontFileList?.value[0]?.fileName,
          idCardBackUrl: cardBackFileList?.value[0]?.fileName,
          step: 1
        });
        userVerifiedStore.saveInfo();
      },

      onDatetimePickerChange,
      formatTime,
      visible,
      showDatePicker,
      verifiedInfo,

      disabled,
      afterUploadedIdCardFront,
      afterUploadedIdCardBack
    };
  }
});
</script>
<style lang="less" scoped>
@import url('../styles/step1.less');
</style>
<style lang="less" scoped>
.drivers-step1-content {
  .idcard {
    .required {
      position: absolute;
      color: red;
      font-size: 0.24rem;
      left: -0.1rem;
    }
  }

  .id-card-wrap {
    padding: 0.08rem 0.16rem;
    .id-card {
      width: 1.6rem;
      height: 1.26rem;
      background: #f6f8fa;
      border-radius: 0.08rem;
    }
  }

  .date-picker {
    font-size: 0.16rem;
    .label {
      color: #646566;
    }
  }
}
</style>
