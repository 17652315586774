<template>
  <van-popup v-model="visiable" position="bottom" round>
    <div class="datetime-picker-wrapper h-full w-full">
      <div class="flex justify-between">
        <span v-if="showClear" @click="onClear" class="text-18 p-12">清空</span>
        <span v-else></span>
        <span class="text-18 p-12 text-primary" @click="onConfirm">确定</span>
      </div>
      <p class="ml-12 py-12">生效日期</p>
      <van-datetime-picker
        visible-item-count="3"
        v-model="innerStartDate"
        type="date"
        :min-date="startMinDate"
        :max-date="startMaxDate"
        :show-toolbar="false"
      />
      <p class="ml-12 py-12">截止日期</p>
      <van-datetime-picker
        visible-item-count="3"
        v-model="innerEndDate"
        :min-date="endMinDate"
        :max-date="endMaxDate"
        type="date"
        :show-toolbar="false"
      />
      <div class="flex justify-between items-center px-24 py-24" v-if="showFooter">
        <span>长期</span>
        <van-switch v-model="checked" @change="onLongTerm" />
      </div>
    </div>
  </van-popup>
</template>

<script>
import { DatetimePicker, Popup, Switch, Toast } from 'vant';
import dayjs from 'dayjs';

const LIMIT_DATE = 7258003200000;

export default {
  components: {
    [DatetimePicker.name]: DatetimePicker,
    [Popup.name]: Popup,
    [Switch.name]: Switch
  },
  props: {
    startDate: {
      // 1622995200000
      default: () => dayjs().valueOf()
    },
    endDate: {
      // 2254147200000
      default: () => dayjs().valueOf()
    },
    value: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    showClear: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checked: false,

      innerStartDate: dayjs(this.startDate ? this.startDate : Date.now()).toDate(),

      innerEndDate: dayjs(this.endDate ? this.endDate : Date.now()).toDate(),

      endDateBak: ''
    };
  },
  created() {
    this.endDateBak = this.endDate;
  },
  methods: {
    onConfirm() {
      const start = dayjs(this.innerStartDate).valueOf();
      const end = dayjs(this.innerEndDate).valueOf();
      if (start > end) {
        Toast('生效日期不能大于截止日期');
        return;
      }
      this.$emit('change', { start, end });
      this.$emit('input', false);
    },
    onClear() {
      this.$emit('change', { start: null, end: null });
      this.$emit('input', false);
    },
    onLongTerm(val) {
      if (val) {
        this.innerEndDate = dayjs(LIMIT_DATE).toDate();
      } else {
        this.innerEndDate = dayjs(this.endDateBak).toDate();
      }

      // 此处van-ui bug，必须设置两次，才能定位到 2019/12/31 否则只能定位到2019/12/30
      this.$nextTick(() => {
        if (val) {
          this.innerEndDate = dayjs(LIMIT_DATE).toDate();
        } else {
          this.innerEndDate = dayjs(this.endDateBak).toDate();
        }
      });
    }
  },
  watch: {
    startDate(timestamp) {
      this.innerStartDate = typeof timestamp === 'number' ? dayjs(timestamp).toDate() : dayjs(Date.now()).toDate();
    },
    endDate(timestamp) {
      this.innerEndDate = typeof timestamp === 'number' ? dayjs(timestamp).toDate() : dayjs(Date.now()).toDate();

      if (timestamp && dayjs(timestamp).year() >= 2199) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    visiable: {
      handler() {
        this.innerEndDate =
          typeof this.endDate === 'number' ? dayjs(this.endDate).toDate() : dayjs(Date.now()).toDate();

        if (this.endDate && dayjs(this.endDate).year() >= 2199) {
          this.checked = true;
        } else {
          this.checked = false;
        }
      },
      immediate: true
    }
  },
  computed: {
    visiable: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    startMinDate() {
      return dayjs('1970/01/02').toDate();
    },
    startMaxDate() {
      return dayjs(this.startDate ? this.startDate : Date.now())
        .add(40, 'year')
        .toDate();
    },
    endMinDate() {
      return dayjs('1970/01/02').toDate();
    },
    endMaxDate() {
      return dayjs(LIMIT_DATE).toDate();
    }
  }
};
</script>

<style></style>
